.shop-now{
    background-color: #395aa8!important;
    border: 2px solid #395aa8!important;
    padding: 12px 24px;
    font-family: 'bold';
    transition: 1s all;
    border-radius: 12px !important;
}
.shop-now:hover{
    border: 2px solid #395aa8 !important;
    background: none !important;
    transition: 1s all;
    border-radius: 12px !important; 
}